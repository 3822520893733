<template>
	<div class="card">
		<table>
			<tbody>
				<tr>
					<td>
						<strong>Payment ID:</strong><br>
						{{ payment.order_id }}
					</td>
					<td>
						<strong>Expiration Date:</strong><br>
						{{ format( new Date(payment.expiration_date), 'MM/dd/yyyy') }}
					</td>
				</tr>
				<tr>
					<td>
						<strong>Paid On:</strong><br>
						{{ format( new Date(payment.created_at), 'MM/dd/yyyy') }}
					</td>
					<td>
						<strong>Amount Paid:</strong><br>
						{{ payment.paid }}
					</td>
				</tr>
				<tr>
					<td colspan="2">
						<strong>Description:</strong><br>
						{{ payment.description }}
					</td>
				</tr>
			</tbody>
		</table>
	</div>
</template>

<script setup>
import { defineProps, reactive } from 'vue';
import { format } from 'date-fns';

const props = defineProps(['payment']);
const payment = reactive(props.payment);
</script>

<style lang="scss" scoped>
	.card {
		border-radius: 5px;
		box-shadow: 0 4px 8px 0 rgba(0,0,0,0.2);
		margin-bottom: 1rem;
		padding: 1rem;
		transition: 0.3s;
	}

	table {
		text-align: left;

		td {
			padding: .5rem;
		}
	}
</style>