// src/services/AuthService.js
// import PageService from './PageService';
import axios from 'axios';
import store from "@/store";
import router from '@/router';

export const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
client.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
		console.log(error);
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters["auth/isLoggedIn"]
    ) {
      store.dispatch("auth/logout");
			router.push('/login');
    }
    return Promise.reject(error);
  }
);

const authUser = async () => await client.get('/api/user');

export default {
	async login(credentials) {
		await client.get('/sanctum/csrf-cookie');
		return await client.post('/api/login', credentials);
  },
  async register(data) {
		await client.get('/sanctum/csrf-cookie');
		return await client.post('/api/register', data);
  },
	async logout() {
		let resp = await client.post('/api/logout');
		authUser();
		return resp;
	},
	async forgotPassword(email) {
		await client.get('/sanctum/csrf-cookie');
		return await client.post('/api/forgot-password', { email });
	},
	async resetPassword(credentials) {
		await client.get('/sanctum/csrf-cookie');
		return await client.post('/api/reset-password', credentials);
	},
	async userStatus() {
		let resp = await client.get('/api/user/status');
		return resp.data;
	},
	authUser
};