import { createRouter, createWebHistory } from 'vue-router'

import Home from '@/views/Home';
import Login from '@/views/Login';
import Register from '@/views/Register';
import ResetPassword from '@/views/ResetPassword';
import Progress from '@/views/Progress';
import Profile from '@/views/Profile';
import Billing from '@/views/Billing';
import Payments from '@/views/Payments';
import Purchase from '@/views/Purchase';
import Map from '@/views/Map';
import store from "@/store";

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
	{
		path: '/map',
		name: 'map',
		component: Map
	},
	{
		path: '/login',
		name: 'login',
		component: Login,
		meta: { public: true }
	},
	{
		path: '/register',
		name: 'register',
		component: Register,
		meta: { public: true }
	},
	{
		path: '/reset-password',
		name: 'reset-password',
		component: ResetPassword,
		meta: { public: true }
	},
	{
		path: '/progress',
		name: 'progress',
		component: Progress
	},
	{
		path: '/profile',
		name: 'profile',
		component: Profile
	},
	{
		path: '/billing',
		name: 'billing',
		component: Billing
	},
	{
		path: '/payments',
		name: 'payments',
		component: Payments
	},
	{
		path: '/purchase',
		name: 'purchase',
		component: Purchase
	}
]

const router = createRouter({
	mode: 'history',
  history: createWebHistory(process.env.BASE_URL),
  routes
});

router.beforeEach(async (to, from, next) => {
	let loggedIn = store.getters['auth/isLoggedIn'];
	let isPublic = to.meta.public;
	if(isPublic && loggedIn) {
		next({ path: '/profile' });
	} else if(!isPublic && !loggedIn) {
		await store.dispatch('auth/syncUser');
		if(store.getters['auth/isLoggedIn']) {
			next();
		} else {
			next({ path: '/login' });
		}
	} else {
		next();
	}

	if(store.getters.getMsg.text != '') store.dispatch('resetMsg');
	if(store.getters.getUserMenu) store.dispatch('setUserMenu', false);
	if(store.getters['program/getShowInstructions']) store.dispatch('program/showInstructions', false);
});

export default router
