<template>
	<h2>
		<img src="/imgs/logo.png" alt="Speechkey Logo">
		SpeechKey
		<small>TM</small>
	</h2>
	<main>
		<section class="form">
			<form @submit.prevent="register">
				<h1>Create Account</h1>
				<label>
					<strong>Email:</strong><br>
					<input class="form-control" type="email" id="email" required v-model="emailAddr">
				</label>
				<label>
					<strong>Password:</strong><br>
					<input class="form-control" type="password" id="password" minlength="6" required v-model="password">
				</label>
				<label>
					<strong>Confirm Password:</strong><br>
					<input class="form-control" type="password" id="password_confirm" minlength="6" required v-model="passwordConfirm">
				</label>
				<label>
					<strong>Native Language:</strong><br>
					<input class="form-control" type="text" id="native_language" v-model="nativeLanguage">
				</label>
				<router-link to="/login"><i class="fa fa-angle-left"></i> Back</router-link>
				<button class="button" type="submit">Create <i class="fa fa-angle-right"></i></button>
				<MsgBox></MsgBox>
			</form>
		</section>
	</main>
</template>

<script>
import { ref } from 'vue';
import { useStore } from 'vuex';
import { useRouter } from 'vue-router';
import MsgBox from '@/components/MsgBox.vue';
import AuthService from '@/services/AuthService.js';

export default {
	components: {
		MsgBox
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const emailAddr = ref('m8brgarc@gmail.com');
		const password = ref('123456');
		const passwordConfirm = ref('123456');
		const nativeLanguage = ref('eng');

		async function register() {
      try {
        const data = {
          email: this.emailAddr,
          password: this.password,
          password_confirmation: this.passwordConfirm,
					native_language: this.nativeLanguage
        };

        await AuthService.register(data);
				store.dispatch('auth/syncUser');
				router.push('/');
      } catch(err) {
				store.dispatch('setMsg', { resp: err.response });
      }
		}

		return {
			emailAddr,
			password,
			passwordConfirm,
			nativeLanguage,
			register
		}
	}
}
</script>

<style lang="scss" scoped>
	h2 {
		color: $white;
		font-size: 2rem;
		margin-top: 1rem;
		text-align: center;


		small {
			color: $primary;
			display: inline-block;
			font-size: 1rem;
			transform: translateY(-9px);
		}

		img {
			transform: translateY(3px);
		}
	}
</style>
