<template>
  <div class="home" v-if="showProgram">
		<component :is="markRaw(lookup[page.template ?? 'Menu'])" :page="page" :store="store" />
		<record-btns v-if="((page.buttons ?? []).length > 0 || page.template == 'Info') && page.template != 'Ipa' && page.template != 'PatternSample'"></record-btns>
		<a class="ipa-back" 
			v-if="ipaId > 0 && ipaId != page.id && (page.buttons.length > 0 && page.buttons.filter(itm => itm.id == ipaBtn).length)" 
			@click.prevent="store.dispatch('program/changePage', ipaId)"
		><i class="fal fa-angle-left"></i> Back to IPA
		</a>
  </div>
	<div class="home" v-else>
		<expire-msg />
	</div>
</template>

<script setup>
import { computed, markRaw, onMounted } from 'vue';
import { useStore } from 'vuex';

import PageService from '@/services/PageService';

import RecordBtns from '@/components/RecordBtns';
import ExpireMsg from '@/components/ExpireMsg';

import Menu from '@/templates/Menu';
import Ipa from '@/templates/Ipa';
import PatternSample from '@/templates/PatternSample';
import ImgExplain from '@/templates/ImgExplain';
import SoundCompare from '@/templates/SoundCompare.vue';
import WordBtns from '@/templates/WordBtns.vue';
import Info from '@/templates/WordBtns.vue';
import SentenceBtns from '@/templates/SentenceBtns.vue';
import MixedBtns from '@/templates/MixedBtns.vue';
import WordCompare from '@/templates/WordCompare.vue';
import RiseFallSen from '@/templates/RiseFallSen.vue';

const store = useStore();
const page = computed(() => store.getters['program/getPage']);
const ipaId = computed(() => store.getters['program/getIpa']);
const ipaBtn = computed(() => store.getters['program/getIpaBtn']);
const showProgram = computed(() => store.getters['auth/isActive']);

const lookup = {
	Menu,
	Ipa,
	PatternSample,
	ImgExplain,
	SoundCompare,
	WordBtns,
	Info,
	SentenceBtns,
	MixedBtns,
	WordCompare,
	RiseFallSen
};

onMounted(async () => {
	let status = await store.dispatch('auth/userStatus');
	if(!status) {
		if(localStorage.getItem('expDate')) {
			PageService.deleteData();
			localStorage.removeItem('expDate');
		}
	} else {
		await PageService.initPages();
	}
});
</script>

<style lang="scss">
	.home {
		background-color: white;
		position: relative;

		& > * {
			padding: 0 2rem;
			width: 100%;
		}

		.ipa-back {
			cursor: pointer;
			display: inline-block;
			padding: .75rem 1.5rem;
		}

		img.explain {
			display: block;
			margin: 0 auto;
			width: 100%;

			&.alt {
				max-width: 300px;
			}
		}

		button {
			background-color: #eee9db;
			border: 1px solid #cfaf89;
			border-radius: 15px;
			color: $black;
			cursor: pointer;
			display: inline;
			font-size: 1rem;
			font-weight: bold;
			margin: 0;
			padding: 14px;
			position: relative;
			text-align: center;
			text-decoration: none;
			transition: position 0.3s ease-in-out;
		}
	}
</style>