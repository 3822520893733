<template>
	<h3>
		<slot><span v-html="page.title"></span>{{ page.max > 1 ? ' - ' + page.page_sort + ' of ' + page.max : '' }}</slot><br>
		<small v-if="(page.title ?? '').indexOf('IPA') > -1">(International Phonetic Alphabet)</small>
	</h3>
</template>

<script setup>
	import { computed } from 'vue';
	import { useStore } from 'vuex';
	const store = useStore();
	const page = computed(() => store.getters['program/getPage']);
</script>

<style lang="scss" scoped>
	h3 {
		font-size: 1.5rem;
		text-align: center;
		width: 100%;

		small {
			font-size: .8rem;
		}

		@media screen and (max-width: 500px) {
			margin-top: .5rem;
			margin-bottom: .75rem;
		}
	}
</style>