<template>
	<mini-load :show="loader"></mini-load>
	<div id="paypal-buttons"></div>
</template>

<script setup>
import { loadScript } from '@paypal/paypal-js';
import { onMounted, defineProps, reactive, ref } from 'vue';
import { useStore } from 'vuex';

import BillingService from '@/services/BillingService';
import PageService from '../services/PageService';

const loader = ref(false);
const store = useStore();
const props = defineProps(['billing','calcDiscount']);
const billing = reactive(props.billing);

const createOrder = (data, actions) => {
	let user = store.getters['auth/getUser'];
	let plan = billing.selectedPlan;
	let discount = billing.selectedDiscount;
	let value = plan.price;
	let description = plan.name;
	let phone = {
		phone_number: {
			national_number: user.phone
		}
	};

	let payer =  {
		email_address: user.email,
		name: {
			given_name: user.billing_first_name,
			surname: user.billing_last_name
		},
		address: {
			postal_code: user.billing_zip,
			country_code: 'US'
		}
	};

	if(user.phone) {
		payer.phone = phone;
	}

	if(discount && discount.plan_ids.indexOf(plan.id) > -1) {
		value = props.calcDiscount(value, discount);
		description += " with Discount: "+discount.code
	}
	value = (parseFloat(value)).toFixed(2);

	return actions.order.create({
		intent: 'AUTHORIZE',
		application_context: {
			shipping_preference: 'NO_SHIPPING',
		},
		purchase_units: [{
			amount: {
				value
			},
			description
		}],
		payer
	});
};
const onApprove = (data, actions) => {
	console.dir(data);

	loader.value = true;

	return actions.order.authorize().then(async authorization => {
    // Get the authorization id
		let orderId = data.orderID;
    let authId = authorization.purchase_units[0].payments.authorizations[0].id
		let planId = billing.selectedPlan.id;
		let discountId = billing.selectedDiscount ? billing.selectedDiscount.id : null;

		let resp = await BillingService.savePayment({orderId, authId, planId, discountId});
		if(resp.status == 200) {
			await PageService.syncMenus();
			await store.dispatch('auth/syncUser');
		}
		store.dispatch('setMsg', { resp });
		loader.value = false;
	});
};
const onError = err => {
	console.dir(err);
	store.dispatch('setMsg', { resp: { data: 'There was a problem with your payment', status: 402 } });
};

let paypal;
const loadPaypalButtons = async () => {
	try {
			paypal = await loadScript({ 
				'client-id': process.env.VUE_APP_CLIENT_ID,
				'disable-funding': 'paylater',
				'intent': 'authorize'
			});
	} catch (error) {
			// console.error("failed to load the PayPal JS SDK script", error);
	}

	if (paypal) {
		try {
			await paypal.Buttons({ createOrder, onApprove, onError }).render("#paypal-buttons");
			loader.value = false;
		} catch (error) {
			// console.error("failed to render the PayPal Buttons", error);
		}
	}
}

onMounted(() => loadPaypalButtons());
</script>