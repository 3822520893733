import axios from 'axios'
import store from '@/store'

export const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  withCredentials: true, // required to handle the CSRF token
});

export default {
	getUser() {
		return axios.get(url + 'user').then(response => {
			let user = response.data;
			store.dispatch('auth/syncUser', { user });
		});
	},
	async updateUser(data) {
		return await client.post('/api/user/update', data);
	},
	async updateUserBtns(btnIds) {
		client.post('/api/user/update-btns', { btnIds });
	}
}