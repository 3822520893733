<template>
  <transition name="fade">
		<div id="loader" v-if="store.getters['program/getShowLoader']">
			<div class="bar">
				<div class="progress" :style="{width: loadingProgress + '%'}"></div>
			</div>

			<strong>{{ loadingText }}</strong>
		</div>
	</transition>
</template>

<script setup>
import { ref, computed, watch } from 'vue';
import { useStore } from 'vuex';

const store = useStore();
// let now = (new Date()).getTime();
// let syncData = now > localStorage.getItem('expDate');
const loadingProgress = computed(() => store.getters['program/getLoadingProgress']);
const loadingText = computed(() => {
	if(loadingProgress.value >= 100) {
		return 'Welcome to SpeechKey';
	} else {
		return 'Loading App...';
	}
});

watch(loadingText, (cur) => {
	if(cur == 'Welcome to SpeechKey') {
		setTimeout(() => {
			store.dispatch('program/showLoader', false);
		}, 1200);
	}
});
</script>

<style lang="scss" scoped>
	#loader {
		align-items: center;
		background-color: $black;
		color: $white;
		display: flex;
		flex-direction: column;
		height: 100vh;
		justify-content: center;
		position: absolute;
		top: 0; right: 0; bottom: 0; left: 0;
		max-width: none;
		text-align: center;
		z-index: 9999;

		.progress {
			background-color: $primary;
			margin: 5px;
			width: 0;
		}
		.bar {
			border: 1px solid $white;
			border-radius: 5px;
			display: flex;
			height: 50px;
			margin-bottom: 2rem;
			max-width: 350px;
			width: 100%;
		}

		strong {
			font-size: 2.25rem;	
		}
	}
	.fade-enter-active,
	.fade-leave-active {
		transition: opacity 0.5s ease;
	}

	.fade-enter-from,
	.fade-leave-to {
		opacity: 0;
	}
</style>