import axios from 'axios';

export const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  withCredentials: true, // required to handle the CSRF token
});

export default {
	async plans() {
		let res = await client.get('/api/plans');
		return res.data;
	},
	async checkDiscount(data) {
		return await client.post('/api/check-discount', data);
	},
	async savePayment(data) {
		return await client.post('/api/user/save-payment', data);
	},
	async payments() {
		let res = await client.get('/api/user/payments');
		return res.data;
	},
	async updateBilling(data) {
		return await client.post('/api/user/update-billing', data);
	}
}