<template>
	<div class="instruction-panel" v-if="store.getters['program/getShowInstructions']">
		<carousel ref="instCarousel" :items-to-show="1">
			<slide v-for="slide, idx in slides" :key="idx">
				<div class="carousel__item">
					<figure>
						<img :src="slide.img" :alt="idx">
						<figcaption v-html="slide.html"></figcaption>
					</figure>
				</div>
			</slide>

			<template #addons>
				<navigation />
				<pagination />
			</template>
		</carousel>
		<button class="button" @click="store.dispatch('program/showInstructions', false)">
			<i class="fal fa-times"></i> Close Instructions
		</button>
	</div>
</template>

<script setup>
import { computed, onMounted, ref } from 'vue';
import { useStore } from 'vuex';

import 'vue3-carousel/dist/carousel.css';
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';

const store = useStore();
const page = computed(() => store.getters['program/getPage']);
const instCarousel = ref(null);

// Slides for each template type and main parts
let path = '/imgs/started/';
let header = {
	img: path+'header.png',
	html: `List icon shows map of the program.<br>The app icon will take you to the program from any other page.<br>Shows instructions for page.<br>User icon allows you to view your account settings and progress.`
};
let desc = {
	img: path+'description.png',
	html: 'Button play audio of text.<br>Text helps you understand the lesson.' 
};
let microphone = {
	img: path+'microphone.png',
	html: 'Must allow use of your microphone to properly utilize the program.'
};
let record = {
	img: path+'recordbtns.png',
	html: 'Record your voice, stop recording, play your recorded voice, compare your recording to native sound'
};
let footer = {
	img: path+'footer.png',
	html: 'Last menu, Previous/Next page, Select page number'
};


// template specific
let ImgExplain = [{
	img: path+'imgexplain.png',
	html: `Title of Lesson is listed at the top.<br>The image moves to show how a sound is produced.<br>Buttons will play native sound or sentences.`
}];
let Ipa = [{
	img: path+'ipalinks.png',
	html: 'Click each sound button to play native sounds, record your voice and compare your recording to the native sound.  If the recording and sound are similar then mark the sound as completed to keep track of which sounds need work.  Lessons and Practice modules for each sound are listed below.'
}];
let Menu = [{
	img: path+'menubtns.png',
	html: 'Menu buttons are used to navigate to each lesson.'
}];
let MixedBtns = [{
	img: path+'mixedbtns.png',
	html: 'Plays native sounds, words or sentences to teach pronounciation'
}];
let RiseFallSen = [{
	img: path+'risefallsen.png',
	html: 'Plays comparison sounds between rising and falling inflections'
}];
let SentenceBtns = [{
	img: path+'sentencebtns.png',
	html: 'Plays audio for each sentence to show different sounds throught the sentence.'
}];
let SoundCompare = [{
	img: path+'soundcompare.png',
	html: 'Plays audio to compare similar sounds to show subtle differences.'
}];
let WordBtns = [{
	img: path+'wordbtns.png',
	html: 'Plays audio for each word see how each word sounds.'
}];
let WordCompare = [{
	img: path+'wordcompare.png',
	html: 'Plays audio to compare similar words to show differences in how to pronounce.'
}];

const instructions = {
	ImgExplain,  
	Ipa,  
	Menu,
	MixedBtns, 
	RiseFallSen, 
	SentenceBtns,  
	SoundCompare, 
	WordBtns, 
	WordCompare  
};

const slides = computed(() => {
	let template = page.value.template ?? 'Menu';
	let hasDesc = page.value.description_id != null;
	let rtn = instructions[template].concat([header]);

	if(hasDesc) rtn.push(desc);
	if(template != 'Menu') rtn.push(microphone, record);
	rtn.push(footer);
	return rtn;
});

onMounted(() => {
	if(page.value.slug != 'home') {
		instCarousel
	}
});
</script>

<style lang="scss">
	.instruction-panel {
		background-color: rgba($black, .95);
		justify-content: flex-start !important;
		max-width: none !important;
    padding: 60px 0 20px 0;
		position: fixed !important;
		top: 0; right: 0; bottom: 0; left: 0;
		z-index: 9997;

		figure {
			img {
				display: block;
				margin-bottom: 1rem;
			}
			figcaption {
				color: $white;
				font-size: .8rem;
				line-height: 1.2;
				text-align: left;
			}
		}

		.carousel__item {
			img {
				max-width: 100%;
				max-height: 100%;
			}
		}

		.carousel__pagination {
			padding-left: 0;
		}
		
		.carousel {
			button {
				background-color: $primary;
				&.carousel__pagination-button {
					background-color: lighten($primary, 20%);

					&--active {
						background-color: $primary;
					}
				}
			}
			@media screen and (max-width: 545px) {
					& > button {
						transform: translate(0, -50%) !important;
						
						&.carousel__next {

						}

						&.carousel__prev {

						}
					}
			}
		}


		button.button {
			width: 200px !important;

			i.fal {
				margin-right: .5rem;
			}
		}
	}
</style>