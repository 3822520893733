<template>
	<div class="progress-page">
		<main>
			<section>
				<h2 class="text-center">Billing Information:</h2>
				<form @submit.prevent="updateBillingInfo">
					<form-field v-model="billingInfo.billing_first_name" required>First Name:</form-field>
					<form-field v-model="billingInfo.billing_last_name" required>Last Name:</form-field>
					<form-field v-model="billingInfo.billing_zip" required>Postal Code:</form-field>
					<button><mini-load :show="loader"><i class="fal fa-credit-card"></i> &nbsp; Update Billing Info</mini-load></button>
					<msg-box></msg-box>
				</form>
			</section>
		</main>
	</div>
</template>

<script setup>
import { ref, reactive } from 'vue';
import { useStore } from 'vuex';

import MsgBox from '@/components/MsgBox';
import FormField from '@/components/FormField';

const store = useStore();
const loader = ref(false);

let user = store.getters['auth/getUser'];
const billingInfo = reactive({
	billing_first_name: user.billing_first_name,
	billing_last_name: user.billing_last_name,
	billing_zip: user.billing_zip
});

const updateBillingInfo = async () => {
	loader.value = true;
	try {
		let resp = await store.dispatch('auth/updateBilling', billingInfo);
		store.dispatch('setMsg', { resp });
	} catch (err) {
		store.dispatch('setMsg', { resp: err.response });
	}
	loader.value = false;
};
</script>

<style lang="scss" scoped>
	.progress-page {
		background-color: white;
	}
</style>