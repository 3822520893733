import AuthService from "@/services/AuthService";
import UserService from "@/services/UserService";
import BillingService from "@/services/BillingService";
import PageService from "@/services/PageService";
import store from "@/store";

export const namespaced = true;

export const state = {
	active: true,
	user: {},
	userBtnTimeout: null,
	lastPgTimeout: null,
	payments: [],
	lastUserPage: '/progress'
};

export const getters = {
	isActive: state => state.active,
	isLoggedIn: state => state.user && Object.keys(state.user).length > 0,
	getUser: state => state.user,
	getUserBtns: state => state.user.buttons,
	getLastPage: state => state.user.page_id,
	getLastPgTimeout: state => state.lastPgTimeout,
	getUserPgs: state => {
		return state.user.pages;
	},
	getUserBtnTimeout: state => {
		return state.userBtnTimeout;
	},
	getLastUserPage: state => {
		return state.lastUserPage;
	},
	hasPayments: state => {
		return state.payments && state.payments.length > 0;
	},
	getFirstPayment: state => {
		return state.payments[0] ?? null;
	},
	getPayments: state => {
		return state.payments;
	},
	isActiveSub: (state, getters) => {
		let validSub = false;
		if(getters.hasPayments) {
			let expDate = new Date(state.payments[0].expiration_date);
			validSub = expDate.valueOf() > Date.now();
		}
		return getters.hasPayments && validSub;
	}
};

export const mutations = {
	SET_ACTIVE: (state, status) => {
		state.active = status;
	},
	SET_USER: (state, user) => {
		state.user = user;
	},
	SET_USER_BTNS: (state, buttons) => {
		state.user.buttons = buttons;
	},
	SET_USER_BTN_TIMEOUT: (state, timeout) => {
		state.userBtnTimeout = timeout;
	},
	SET_LAST_PAGE: (state, id) => {
		state.user.page_id = id;
	},
	SET_LAST_PAGE_TIMEOUT: (state, timeout) => {
		state.lastPgTimeout = timeout;
	},
	SET_PAYMENTS: (state, payments) => {
		state.payments = payments;
	}
};

export const actions = {
	async userStatus({ commit }) {
		let status = await AuthService.userStatus();
		commit('SET_ACTIVE', status);
		return status;
	},
	async syncUser({ commit, dispatch }) {
		try {
			let resp = await AuthService.authUser();
			let user = resp.data.user;
			let payments = resp.data.payments;
			let page = resp.data.page;
			let progress = resp.data.progress;
			await store.dispatch('auth/login', { user, payments });
			commit('program/SET_PROGRESS', progress, { root: true });
			dispatch('program/setMap', null, { root: true });
			if(user.page_id) {
				await PageService.savePage(page);
				dispatch('program/changePage', page.id, { root: true });
			} else {
				dispatch('program/changePage', 'home', { root: true });
			}

			return true;
		} catch (err) {
			console.log(err);
			return false;
		}
	},
	async updateUser({ commit }, data) {
		const resp = await UserService.updateUser(data);
		commit('SET_USER', resp.data.user);
		return resp;
	},
	async saveUserBtns({ commit, getters }, data) {
		let timeout = getters.getUserBtnTimeout;
    clearTimeout(timeout);
    timeout = setTimeout(() => UserService.updateUserBtns(data), 1000);
		commit('SET_USER_BTN_TIMEOUT', timeout);
		commit('SET_USER_BTNS', data);
	},
	async updateBilling({ commit }, data) {
		const resp = await BillingService.updateBilling(data);
		commit('SET_USER', resp.data.user);
		return resp;
	},
	async payments({ commit }) {
		const resp = await BillingService.payments();
		commit('SET_PAYMENTS', resp);
	},
	async login({ commit }, { user, payments }) {
		commit('SET_USER', user);
		commit('SET_PAYMENTS', payments);
	},
	logout: ({ commit }) => {
		commit('SET_USER', {});
		localStorage.removeItem('expDate');
		PageService.deleteData();
	}
};