
import axios from 'axios';
import Localbase from 'localbase'
import store from "@/store";

const db = new Localbase('db');

export const client = axios.create({
  baseURL: process.env.VUE_APP_ROOT_API,
  withCredentials: true, // required to handle the CSRF token
});

/*
 * Add a response interceptor
 */
client.interceptors.response.use(
  (response) => {
    return response;
  },
  function (error) {
		console.log(error);
    if (
      error.response &&
      [401, 419].includes(error.response.status) &&
      store.getters["auth/isLoggedIn"]
    ) {
      store.dispatch("auth/logout");
			router.push('/login');
    }
    return Promise.reject(error);
  }
);

const setExpDate = () => {
	let expDate = new Date();
	expDate = expDate.setMonth(expDate.getMonth() + 1);
	localStorage.setItem('expDate', expDate);
}
const getMaxDate = async () => {
	let resp = await client.get('/api/pages/max');
	return resp.data;
}
const setLocalPages = async (items) => {
	for(let i=0; i<items.length; i++) {
		await db.collection('pages').doc(items[i].id.toString()).set(items[i]);
	}
}
const syncUserPage = async () => {
	let resp = await client.get('/api/pages/user');
	setLocalPages(resp.data);
}
const syncPage = async id => {
	console.log('syncPage');
	let resp = await client.get('/api/pages/'+id);
	setLocalPages([resp.data]);
}
const syncMenus = async date => {	
	console.log('syncMenus');
	let resp = await client.post('/api/menus', { date });
	setLocalPages(resp.data);
}
const syncLessons = async date => {
	console.log('syncLesson');
	let resp = await client.post('/api/lessons', { date });
	setLocalPages(resp.data);
}

// initial pages/lesssons import
const importPages = async () => {
	// store.dispatch('program/showLoader', true);
	// import user last page and surrounding pages
	await syncUserPage();

	if(!store.getters['auth/getUser'].page_id) {
		await store.dispatch('program/changePage', 'home');
	} else {
		await store.dispatch('program/changePage', store.getters['auth/getUser'].page_id);
	}

	// import menu pages
	await syncMenus();

	// import lesson pages
	await syncLessons();
};

// update existing indexedDB pages
const syncPages = async () => {
	console.log('syncMenus');
	// get max updated_at for menus
	let maxDate = (new Date(await getMaxDate())).getTime();
	let expDate = parseInt(localStorage.getItem('expDate') ?? 0);

	// if expDate < maxDate
	console.log(expDate, new Date(expDate));
	console.log(maxDate, new Date(maxDate));
	if(expDate < maxDate || expDate == 0) {
		console.log('refresh');

		await syncUserPage();

		let user = store.getters['auth/getUser'];
		if(!user.page_id) {
			store.dispatch('program/changePage', 'home');
		} else {
			store.dispatch('program/changePage', user.page_id);
		}

		// syncMenus where updated_at > expDate
		await syncMenus(expDate);
		
		// syncLessons where updated_at > expDate
		await syncLessons(expDate);
		
		// save maxDate as expDate localStorage
		localStorage.setItem('expDate', (new Date(maxDate)).getTime());
	}
};

const initPages = async () => {
	let coll = await db.collection('pages').doc('1').get();
	if(coll) {
		console.log('db exists');
		syncPages();
	} else {
		console.log('db none');
		importPages();
	}
}

export default {
	setExpDate,
	initPages,
	syncPage,
	syncMenus,
	syncLessons,
	async savePage(page) {
		await db.collection('pages').doc(page.id.toString()).set(page);
	},
	async saveProgress(id) {
		client.post('/api/user/save-progress', { pageId: id });
	},
	async setLastPage(id) {
		client.post('/api/user/save-last-page', { pageId: id });
	},
	async getMap() {
		return await client.get('/api/map');
	},
	deleteData() {
		console.log('deleting data');
		db.collection('pages').delete();
	}
}