<template>
	<div class="progress-page">
		<main>
			<section v-if="store.getters['auth/hasPayments']">
				<h2 class="text-center">Latest Payment:</h2>
				<payment-data :payment="payment"></payment-data>
			</section>
			<section class="form">
				<h2 class="text-center">Learning Packages:</h2>
				<div class="plans">
					<label v-for="plan in billing.plans" :key="plan.id" :class="{active: billing.selectedPlan.id == plan.id}">
						<input type="radio" v-model="billing.selectedPlan" :value="plan"> 
						<span>{{ plan.length_amount }}</span>
						<small>{{ plan.length_type.toUpperCase() }}</small>
						<i :class="{ 'strike-out': plan.discounted }">${{ parseFloat(plan.price).toFixed(2) }}</i>
						<i v-if="plan.discounted" class="discount-amount">${{ plan.discounted.toFixed(2) }}</i>
					</label>
				</div>

				<form class="discount" @submit.prevent="applyDiscount">
					<input class="form-control" type="text" v-model="discountCode" required> <button class="button"><mini-load :show="loaders.discount">Apply Discount</mini-load></button>
				</form>
				<msg-box></msg-box>
				<hr>
				<br>
				<paypal-buttons :calcDiscount="calcDiscount" :billing="billing"></paypal-buttons>
			</section>
		</main>
	</div>
</template>

<script setup>
import { onMounted, computed, ref, reactive } from 'vue';
import { useStore } from 'vuex';

import BillingService from '@/services/BillingService';

// import UserHeader from '@/components/UserHeader';
import MsgBox from '@/components/MsgBox';
import FormField from '@/components/FormField';
import PaymentData from '@/components/PaymentData';
import PaypalButtons from '@/components/PaypalButtons';

const store = useStore();
const discountCode = ref('');
const loaders = reactive({
	discount: false
});

const payment = computed(() => store.getters['auth/getFirstPayment']);

const billing = reactive({
	plans: [],
	selectedPlan: null,
	selectedDiscount: null
});

const getBilling = async () => {
	billing.plans = await BillingService.plans();
	billing.selectedPlan = billing.plans[1];
}

let calcDiscount = (price, discount) => {
	let newAmount = price;
	switch(discount.type) {
		case 'percent':
			newAmount = (parseFloat(discount.amount)/100) * price;
			break;
		case 'fixed':
			newAmount = (price - parseFloat(discount.amount));
			if(newAmount < 0) newAmount = 0;
			break;
	}
	return newAmount;
}

const applyDiscount = async () => {
	loaders.discount = true;
	let code = discountCode.value;
	billing.plans.forEach(itm => delete itm.discounted);
	try {
		let resp = await BillingService.checkDiscount({ code });
		billing.selectedDiscount = resp.data.discount;
		
		billing.plans.forEach(plan => {
			if(billing.selectedDiscount.plan_ids.indexOf(plan.id) > -1) {
				plan.discounted = calcDiscount(plan.price, billing.selectedDiscount);
			}
		})
		store.dispatch('setMsg', { resp });
	} catch (err) {
		store.dispatch('setMsg', { resp: err.response });
	}
	loaders.discount = false;
}

onMounted(() => getBilling());
</script>

<style lang="scss" scoped>
	.progress-page {
		background-color: white;
		
		fieldset {
			display: flex;
			width: 100%;
		}

		.payments {
			padding: 1rem;
			max-height: 300px;
			overflow-y: auto;
		}

		.plans {
			display: flex;
			justify-content: space-between;
			
			label {
				border: 3px solid #eee; 
				opacity: .6;
				min-width: 30%;
				padding: 2rem;
				position: relative;
				text-align: center;
				transition: all .4s;

				&:after {
					border: 2px solid darkgreen;
					border-radius: 25px;
					color: darkgreen;
					content: '\02713';
					opacity: 0;
					padding: 0 .25rem;
					position: absolute;
					top: 5px; left: 5px;
					transition: all .4s;
				}

				input { display: none; }
				span {
					font-size: 1.75rem;
				}
				small {
					display: block;
				}
				
				i {
					display: inline-block;
					font-size: 1.05rem;
					margin-top: 1rem;

					&.strike-out {
						text-decoration: red line-through;
					}

					&.discount-amount {
						color: green;
						position: absolute;
						bottom: 10px; left: 50%;
						transform: translateX(-50%);
					}
				}

				&.active {
					border: 3px solid darkgreen;
					opacity: 1;
					position: relative;

					&:after {
						opacity: 1;
					}
				}
			}
		}
		.discount {
			display: flex;
			margin: 1rem auto 2rem;
			max-width: 80%;

			button {
				flex-grow: 1;
				margin-left: 2rem;
				white-space: nowrap;
			}
		}
	}
</style>