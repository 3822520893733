<template>
	<div class="progress-page">
		<main>
			<section class="form">
				<h2 class="text-center">Payment History:</h2>
				<div v-if="store.getters['auth/getPayments'].length" class="payments">
					<payment-data v-for="payment in store.getters['auth/getPayments']" :key="payment.id" :payment="payment"></payment-data>
				</div>	
				<div v-else>
					<br><br><br>
					<h3 class="text-center">No payment history yet...</h3>
				</div>
			</section>
		</main>
	</div>
</template>

<script setup>
import { computed } from 'vue';
import { useStore } from 'vuex';

import PaymentData from '@/components/PaymentData';

const store = useStore();
</script>

<style lang="scss" scoped>
	.progress-page {
		background-color: white;

		.payments {
			padding: 1rem;
			overflow-y: auto;
		}
	}
</style>