<template>
	<div>
		<h2>
			<img src="/imgs/logo.png" alt="Speechkey Logo">
			SpeechKey
			<small>TM</small>
		</h2>
		<main>
			<section class="form">
				<form @submit.prevent="resetPassword">
					<h1>Reset Password</h1>
					<label>
						<strong>Email:</strong><br>
						<input class="form-control" type="email" id="email" required v-model="email">
					</label>
					<label>
						<strong>New Password:</strong>
						<input type="password" class="form-control" id="password" required v-model="password">
					</label>
					<label>
						<strong>Confirm New Password:</strong><br>
						<input class="form-control" type="password" id="passwordConfirm" required v-model="passwordConfirm">
					</label>
					<button class="button" type="submit">Reset Password <i class="fal fa-angle-left"></i></button>
					<MsgBox></MsgBox>
				</form>
			</section>
		</main>
	</div>
</template>

<script>
import { ref } from 'vue'
import { useStore } from 'vuex';
import { useRouter, useRoute } from 'vue-router';

import MsgBox from '@/components/MsgBox.vue'
import AuthService from '@/services/AuthService.js'

export default {
	components: {
		MsgBox
	},
	setup() {
		const store = useStore();
		const router = useRouter();
		const route = useRoute();
		const email = ref(route.query.email);
		const password = ref('');
		const passwordConfirm = ref('');
		

		async function resetPassword() {
			let credentials = {
				token: route.query.token,
				email: this.email,
				password: this.password,
				password_confirmation: this.passwordConfirm
			};

			try {
				let resp = await AuthService.resetPassword(credentials);
				await store.dispatch('auth/login', resp.data.user);
				router.push({name: 'home'});
			} catch (err) {
				let resp = err;
				store.dispatch('setMsg', { resp })
			}
		}

		return {
			email,
			password,
			passwordConfirm,
			resetPassword
		}
	}
};
</script>

<style lang="scss" scoped>
	h2 {
		color: $white;
		font-size: 2rem;
		margin-top: 1rem;
		text-align: center;


		small {
			color: $primary;
			display: inline-block;
			font-size: 1rem;
			transform: translateY(-9px);
		}

		img {
			transform: translateY(3px);
		}
	}
</style>