<template>
	<label>
		<strong><slot></slot></strong>
		<input type="text" class="form-control" v-bind="$attrs" v-model="val"	@input="handleInput">
	</label>	
</template>

<script>
	export default {
		inheritAttrs: false
	}
</script>

<script setup>
	import { useAttrs, defineProps, defineEmits, ref } from 'vue';
	const attrs = useAttrs();
	const props = defineProps(['modelValue']);
	const emits = defineEmits(['update:modelValue']);
	const val = ref(props.modelValue);
	const handleInput = () => emits('update:modelValue', val.value);
</script>

<style lang="scss" scoped>
	label {
		display: block;
		margin-bottom: 1rem;

		strong {
			display: block;
			margin-bottom: .25rem;
		}

		input {
			width: 100%;
		}
	}
</style>